import React from 'react';
import Reveal from 'react-reveal/Reveal/';

const DesignBanner = () => {
    return(
        <section className="seo_home_area">
            <div className="banner_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center seo_banner_content">
                            <Reveal effect="fadeInUp" duration={500}><h2>Data Analytics<br/>for the Cold Chain</h2></Reveal>
                            <Reveal effect="fadeInLeft" duration={1200}><a href="./" className="seo_btn seo_btn_two btn_hover wow fadeInRight">Learn More</a></Reveal>
                        </div>
                    </div>
                    <div className="saas_home_img">
                        <Reveal effect="fadeInUp" duration={1400}><img src={require('../../img/seo/image.png')} alt=""/></Reveal>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default DesignBanner;
